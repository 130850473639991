@import "../variables";

.sm-page-widget-google-calendar {
    width: 100%;
    position: relative;
}

.sm-google-calendar-auto .sm-google-calendar-container {
    padding-bottom: 75%;
}

.sm-google-calendar-auto .sm-google-calendar-container .sm-google-calendar-iframe {
    position: absolute;
}

.sm-google-calendar-container .sm-google-calendar-iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sm-google-calendar-container .sm-google-calendar-iframe.show-border {
    border: solid 1px #777;
}

/**
 * Mobile styles
 */
@media screen and (max-width: @small-max-width) {
    .sm-google-calendar-container .sm-google-calendar-iframe {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .sm-google-calendar-container {
        height: 0 !important;
        padding-bottom: 75%;
    }
}

/* iPhone specific */
@media only screen
and (max-device-width : 320px) {
    .sm-google-calendar-container .sm-google-calendar-iframe {
        position: relative;
        height: 225px;
        width: 295px;
    }
}
